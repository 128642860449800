<template>
  <div>
    <div class="vip-type-list" style="font-size:16px">
      <span>商品-{{this.$route.query.name}} 提成详情（  {{this.$route.query.dateType == '班次'?this.$route.query.dateType:''}}  {{this.$route.query.dateMin }} <span v-if="this.$route.query.dateType!== '班次'">至 {{this.$route.query.dateMax}}</span> ）</span>
      <div style="display:flex;align-items:center;">
        <el-button type="success" @click="this.$router.go(-1)">返回</el-button>
      </div>
    </div>
    <main  style="margin:0 20px">
      <div class="vip-type-list" style="font-size:14px">
        <span style="font-size:14px">销售总额：{{salesAmount}}    提成金额：{{royalty}} </span>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="tableData" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
        <el-table-column prop="userName" label="姓名" />
        <el-table-column prop="staffType" label="角色" />
        <el-table-column prop="itemCount" label="销售数量" />
        <el-table-column label="销售金额" >
          <template #default="props">
            <span>{{(Number(props.row.salesAmount)).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="提成金额">
          <template #default="props">
            <span>{{(Number(props.row.royalty)).toFixed(2)}}</span>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <Print rul='report/exportAchievementWaiter.json' :add='"&entityId="+this.$route.query.entityId+"&type="+this.$route.query.type' />
    </main>

  </div>
</template>

<script>
import {ref, watch} from 'vue'
import {useRoute} from'vue-router'
import {postData} from '@a'
export default {
  setup(){
// 分类中的
    let route = useRoute()
    const tableData = ref([
      {
        date:200
      }
    ])
    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '400'
      style['padding-left'] = '20px'
      style['background-color'] = '#D7D7D7'
      return style
    }
    const cellStyle = ()=>{
      let style  = {}
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      return style
    }
    let salesAmount = ref(0)
    let royalty = ref(0)
     watch(()=>route.query,()=>{
       if(route.name === 'deductGoods')
       info()
     })
    let pagName = 1
    pagName++
    console.log(pagName)
    const info = async()=>{
      let param = {
        entityId:route.query.entityId,
        shopId:route.query.shopId,
        type:route.query.type
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyId = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))        }
      }
      try {
          let res = await(postData('report/achievement/items.json',param))
          for(let i = 0;i<res.data.length;i++){
            for (var obj in  res.data[i].users[0]) {
                res.data[i][obj] = res.data[i].users[0][obj];
                if(obj == 'staffType'){
                  res.data[i][obj] = res.data[i][obj]==0?'服务员':res.data[i][obj]==1?'收银员':res.data[i][obj]==2?'经理':'老板'
                }
            }
          }
          tableData.value = res.data
          salesAmount.value = 0
          royalty.value = 0
          tableData.value.forEach((e) => {
            salesAmount.value += Number(e.salesAmount)
            royalty.value += Number(e.royalty)
            e.check = '查看'
          })
          console.log(tableData.value)
      } catch (error) {
        console.log(error)
      }
    }
    info()
    return{
      tableData,
      headerStyle,
      cellStyle,
      salesAmount,
      royalty,
    }
  }
}
</script>
<style scoped>
.vip-type-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 10px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}


.date-box{
    width: 274px;
    height: 32px;
    margin-right: 20px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-left{
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  border-right: 1px solid #b4b4b4;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
}
.box-right{
  text-align: right;
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  font-size: 16px;
  line-height: 25px;
  border-left: 1px solid #b4b4b4;
  cursor: pointer;
}
</style>